import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { getStatement, resetProcess, setExamData, setGrade, setId, setStartTime, setStatus } from 'store/examProcess';
import { processStatus } from 'util/constants';
import { getExam, getProcess } from 'util/request';
import Created from './created';
import Started from './started';
import Finished from './finished';

const ExamProcess = ({ review }) => {
  const { processId, examId } = useParams();
  const dispatch = useDispatch();
  const examProcess = useSelector(state => state.examProcess);

  const processQuery = useQuery(
    ['process', processId],
    () => getProcess(processId)
      .then(({ data }) => data)
  );
  const examQuery = useQuery(
    ['exam', examId],
    () => getExam(examId)
      .then(({ data }) => data)
  );
  console.log('examId, ',examId);
  useEffect(() => {
    if (examId) {
      console.log('danm');
      dispatch(getStatement(examId));
    }
  }, [examId]);

  useEffect(() => {
    if (examQuery.data && processQuery.data) {
      dispatch(setId(examId));
      dispatch(setStatus(examQuery.data.status_display));
      dispatch(setGrade(examQuery.data.grade));
      dispatch(setStartTime(examQuery.data.started_at));
      dispatch(setExamData({
        examProcess: processQuery.data.exam_process,
        setup: examQuery.data.exam.exam_setup,
        institutionData: processQuery.data.institution_career,
      }));
    }

    return () => {
      dispatch(resetProcess());
    }
  }, [examQuery.data, processQuery.data]);


  const renderContent = () => {
    if (review) {
      return <Started />;
    }
    switch (examProcess.status) {
      case processStatus.CREATED:
        return <Created />;
      case processStatus.STARTED:
        return <Started />;
      case processStatus.TERMINATED:
        return <Finished />;
    }
  }

  return renderContent();
};

export default ExamProcess;