import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/integration/react';
import WebFont from 'webfontloader';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import THEME, { GlobalStyle } from 'util/theme';
import Router from './router';
import { persistor, store } from './store';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      // staleTime: 1000*60*30,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      // refetchOnMount: true,
      retry: 1,
    },
  },
});

const App = () => {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Sora: 400, 500, 600, 700', 'Source Sans 3: 400, 500, 600, 700']
      }
    });
  }, []);

  return (
    <div className="App">
      <GlobalStyle />
      <ThemeProvider theme={THEME}>
        <QueryClientProvider client={client}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Router />
              <ToastContainer
                hideProgressBar
                position='bottom-right'
                theme='colored'
              />
              {process.env.NODE_ENV !== 'production' && (
                <ReactQueryDevtools />
              )}
            </PersistGate>
          </Provider>
        </QueryClientProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
