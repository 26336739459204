import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Container, Grid, Row, Typography } from 'components/atoms';

const ExamRow = ({
  data,
  loading,
  onStart,
}) => (
  <Card bgVariant='lightest' width='100%' padding={8}>
    <Container
      width='100%'
      direction='column'
      alignItems='center'
      gap={16}
      md={{
        direction: 'row',
      }}
    >
      <Container
        width='100%'
        direction='row'
        justify='center'
        gap={16}
        md={{
          flex: 1,
        }}
      >
        <Container width='20%'>
          <Typography as='p'>
            {data.institution.abbreviation}
          </Typography>
        </Container>

        <Container width='60%'>
          <Typography as='span'>
            {data.description}
          </Typography>
        </Container>

        <Container width='20%'>
          <Typography as='span'>
            {data.year} - {data.phase}
          </Typography>
        </Container>
      </Container>

      <Container
        width='100%'
        md={{
          width: 'auto',
        }}
      >
        <Button
          loading={loading}
          width='100%'
          weight={400}
          content='Crear examen'
          onClick={onStart}
        />
      </Container>
    </Container>
    {/*<Grid>*/}
    {/*  <Row alignItems='center' gap={16}>*/}
    {/*    <Col sm={4}>*/}
    {/*      <Typography as='p'>*/}
    {/*        {data.institution.abbreviation}*/}
    {/*      </Typography>*/}
    {/*    </Col>*/}
    {/*    <Col sm={6}>*/}
    {/*      <Typography as='span'>*/}
    {/*        {data.description}*/}
    {/*      </Typography>*/}
    {/*    </Col>*/}
    {/*    <Col sm={2}>*/}
    {/*      <Typography as='span'>*/}
    {/*        {data.year} - {data.phase}*/}
    {/*      </Typography>*/}
    {/*    </Col>*/}
    {/*    <Col sm={4}>*/}
    {/*      <Button*/}
    {/*        loading={loading}*/}
    {/*        width='100%'*/}
    {/*        weight={400}*/}
    {/*        content='Crear examen'*/}
    {/*        onClick={onStart}*/}
    {/*      />*/}
    {/*    </Col>*/}
    {/*  </Row>*/}
    {/*</Grid>*/}
  </Card>
);

ExamRow.propTypes = {
  data: PropTypes.any.isRequired,
  loading: PropTypes.bool,
  onStart: PropTypes.func.isRequired,
};

ExamRow.defaultProps = {
  loading: false,
};

export default ExamRow;