import styled from 'styled-components';
import PropTypes from 'prop-types';
import { media } from 'util/responsive';
import { colorValues, variantValues } from 'util/theme';
import { alignValues } from 'util/constants';

const Container = styled.div(props => ({
  display: props.display,
  position: props.position,
  flexDirection: props.direction,
  alignItems: props.alignItems,
  justifyContent: props.justify,
  flex: props.flex,
  width: props.width,
  height: props.height,
  minWidth: props.minWidth,
  minHeight: props.minHeight,
  maxWidth: props.maxWidth,
  maxHeight: props.maxHeight,
  zIndex: props.zIndex,
  gap: props.gap,
  backgroundColor: (props.bgColor && props.bgVariant) ? props.theme.colors[props.bgColor][props.bgVariant] : 'none',
  border: (props.borderWidth && props.borderColor && props.borderVariant) ? `${props.borderWidth}px solid ${props.theme.colors[props.borderColor][props.borderVariant]}` : 'none',
  borderRadius: props.borderRadius,
  margin: props.margin,
  padding: props.padding,
  marginTop: props.mt,
  marginRight: props.mr,
  marginBottom: props.mb,
  marginLeft: props.ml,
  paddingTop: props.pt,
  paddingRight: props.pr,
  paddingBottom: props.pb,
  paddingLeft: props.pl,

  ...(props.sm && ({
    '@media only screen and (width > 768px)': {
      display: props.sm.display,
      flexDirection: props.sm.direction,
      alignItems: props.sm.alignItems,
      justifyContent: props.sm.justify,
      flex: props.sm.flex,
      width: props.sm.width,
      height: props.sm.height,
      minWidth: props.sm.minWidth,
      minHeight: props.sm.minHeight,
      maxWidth: props.sm.maxWidth,
      maxHeight: props.sm.maxHeight,
      gap: props.sm.gap,
      margin: props.sm.margin,
      padding: props.sm.padding,
      marginTop: props.sm.mt,
      marginRight: props.sm.mr,
      marginBottom: props.sm.mb,
      marginLeft: props.sm.ml,
      paddingTop: props.sm.pt,
      paddingRight: props.sm.pr,
      paddingBottom: props.sm.pb,
      paddingLeft: props.sm.pl,
    }
  })),
  ...(props.md && ({
    '@media only screen and (width > 992px)': {
      display: props.md.display,
      flexDirection: props.md.direction,
      alignItems: props.md.alignItems,
      justifyContent: props.md.justify,
      flex: props.md.flex,
      width: props.md.width,
      height: props.md.height,
      minWidth: props.md.minWidth,
      minHeight: props.md.minHeight,
      maxWidth: props.md.maxWidth,
      maxHeight: props.md.maxHeight,
      gap: props.md.gap,
      margin: props.md.margin,
      padding: props.md.padding,
      marginTop: props.md.mt,
      marginRight: props.md.mr,
      marginBottom: props.md.mb,
      marginLeft: props.md.ml,
      paddingTop: props.md.pt,
      paddingRight: props.md.pr,
      paddingBottom: props.md.pb,
      paddingLeft: props.md.pl,
    }
  })),
}));

// const Container = styled.div`
//   //display: ${props => props.display === 'inline' ? 'inline-flex': 'flex'};
//   //flex-direction: ${props => props.direction};
//   //border-radius: ${props => `${props.borderRadius}px`};
//   // ${props => props.flex && `flex: ${props.flex};`}
//   // ${props => props.position && `position: ${props.position};`}
//   //${props => props.index && `z-index: ${props.index};`}
//   //${props => props.bgColor && `background-color: ${props.theme.colors[props.bgColor][props.bgVariant]};`}
//   // ${props => props.justify && `justify-content: ${props.justify};`}
//   // ${props => props.alignItems && `align-items: ${props.alignItems};`}
//   // ${props => props.width && `width: ${typeof props.width === 'string' ? props.width : `${props.width}px`};`}
//   // ${props => props.height && `height: ${typeof props.height === 'string' ? props.height : `${props.height}px`};`}
//   // ${props => props.minWidth && `min-width: ${typeof props.minWidth === 'string' ? props.minWidth : `${props.minWidth}px`};`}
//   // ${props => props.minHeight && `min-height: ${typeof props.minHeight === 'string' ? props.minHeight : `${props.minHeight}px`};`}
//   // ${props => props.maxWidth && `max-width: ${typeof props.maxWidth === 'string' ? props.maxWidth : `${props.maxWidth}px`};`}
//   // ${props => props.maxHeight && `max-height: ${typeof props.maxHeight === 'string' ? props.maxHeight : `${props.maxHeight}px`};`}
//   //${props => props.gap && `gap: ${props.gap}px;`}
//   //${props => props.borderColor && `border: ${props.borderWidth}px solid ${props.theme.colors[props.borderColor][props.borderVariant]};`}
//   // margin: ${props => `${props.margin}px`};
//   // ${props => props.mb ? `margin-bottom: ${props.mb}px;` : ''}
//   // ${props => props.mt ? `margin-top: ${props.mt}px;` : ''}
//   // ${props => props.mr ? `margin-right: ${props.mr}px;` : ''}
//   // ${props => props.ml ? `margin-left: ${props.ml}px;` : ''}
//   // padding: ${props => `${props.padding}px`};
//   // ${props => props.pb ? `padding-bottom: ${props.pb}px;` : ''}
//   // ${props => props.pt ? `padding-top: ${props.pt}px;` : ''}
//   // ${props => props.pr ? `padding-right: ${props.pr}px;` : ''}
//   // ${props => props.pl ? `padding-left: ${props.pl}px;` : ''}
//
//   ${props => props.xs && media['xs'](`
//     ${props.xs.mb ? `margin-bottom: ${props.xs.mb}px;` : ''}
//     ${props.xs.mt ? `margin-top: ${props.xs.mt}px;` : ''}
//     ${props.xs.ml ? `margin-left: ${props.xs.ml}px;` : ''}
//     ${props.xs.mr ? `margin-right: ${props.xs.mr}px;` : ''}
//     ${props.xs.pb ? `padding-bottom: ${props.xs.pb}px;` : ''}
//     ${props.xs.pt ? `padding-top: ${props.xs.pt}px;` : ''}
//     ${props.xs.pl ? `padding-left: ${props.xs.pl}px;` : ''}
//     ${props.xs.pr ? `padding-right: ${props.xs.pr}px;` : ''}
//   `)}
//   ${props => props.sm && media['sm'](`
//     ${props.sm.mb ? `margin-bottom: ${props.sm.mb}px;` : ''}
//     ${props.sm.mt ? `margin-top: ${props.sm.mt}px;` : ''}
//     ${props.sm.ml ? `margin-left: ${props.sm.ml}px;` : ''}
//     ${props.sm.mr ? `margin-right: ${props.sm.mr}px;` : ''}
//     ${props.sm.pb ? `padding-bottom: ${props.sm.pb}px;` : ''}
//     ${props.sm.pt ? `padding-top: ${props.sm.pt}px;` : ''}
//     ${props.sm.pl ? `padding-left: ${props.sm.pl}px;` : ''}
//     ${props.sm.pr ? `padding-right: ${props.sm.pr}px;` : ''}
//   `)}
//   ${props => props.md && media['md'](`
//     ${props.md.mb ? `margin-bottom: ${props.md.mb}px;` : ''}
//     ${props.md.mt ? `margin-top: ${props.md.mt}px;` : ''}
//     ${props.md.ml ? `margin-left: ${props.md.ml}px;` : ''}
//     ${props.md.mr ? `margin-right: ${props.md.mr}px;` : ''}
//     ${props.md.pb ? `padding-bottom: ${props.md.pb}px;` : ''}
//     ${props.md.pt ? `padding-top: ${props.md.pt}px;` : ''}
//     ${props.md.pl ? `padding-left: ${props.md.pl}px;` : ''}
//     ${props.md.pr ? `padding-right: ${props.md.pr}px;` : ''}
//   `)}
//   ${props => props.lg && media['lg'](`
//     ${props.lg.mb ? `margin-bottom: ${props.lg.mb}px;` : ''}
//     ${props.lg.mt ? `margin-top: ${props.lg.mt}px;` : ''}
//     ${props.lg.ml ? `margin-left: ${props.lg.ml}px;` : ''}
//     ${props.lg.mr ? `margin-right: ${props.lg.mr}px;` : ''}
//     ${props.lg.pb ? `padding-bottom: ${props.lg.pb}px;` : ''}
//     ${props.lg.pt ? `padding-top: ${props.lg.pt}px;` : ''}
//     ${props.lg.pl ? `padding-left: ${props.lg.pl}px;` : ''}
//     ${props.lg.pr ? `padding-right: ${props.lg.pr}px;` : ''}
//   `)}
// `;

Container.propTypes = {
  display: PropTypes.oneOf(['flex', 'inline']),
  direction: PropTypes.oneOf(['row', 'column', 'row-reverse', 'column-reverse']),
  position: PropTypes.oneOf(['static', 'absolute', 'relative', 'fixed']),
  zIndex: PropTypes.number,
  gap: PropTypes.number,
  bgColor: PropTypes.oneOf(colorValues),
  bgVariant: PropTypes.oneOf(variantValues),
  justify: PropTypes.oneOf(alignValues),
  alignItems: PropTypes.oneOf(alignValues),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  flex: PropTypes.number,
  borderWidth: PropTypes.number,
  borderColor: PropTypes.oneOf(colorValues),
  borderVariant: PropTypes.oneOf(variantValues),
  borderRadius: PropTypes.number,
};

Container.defaultProps = {
  display: 'flex',
  direction: 'column',
  position: undefined,
  zIndex: undefined,
  gap: undefined,
  bgColor: undefined,
  bgVariant: 'medium',
  justify: 'flex-start',
  alignItems: 'flex-start',
  width: undefined,
  height: undefined,
  minWidth: undefined,
  minHeight: undefined,
  maxWidth: undefined,
  maxHeight: undefined,
  margin: 0,
  padding: 0,
  flex: undefined,
  borderWidth: 1,
  borderColor: undefined,
  borderVariant: 'medium',
  borderRadius: undefined,
};

export default Container;