import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col } from 'react-simple-flex-grid';
import { useQuery } from '@tanstack/react-query';
import { faCircleCheck, faCircleQuestion, faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { faArrowTrendUp, faAward, faChartSimple } from '@fortawesome/free-solid-svg-icons';
import { Button, Container, Icon, Typography } from 'components/atoms';
import { Chart, StatsCard } from 'components/molecules';
import { getExamRanking, getProcess } from 'util/request';
import THEME from 'util/theme';
import { DataTable } from '../../../../components/organisms';
import { formatDate } from '../../../../util/functions';

const MyExamDetail = () => {
  const navigate = useNavigate();
  const { processId } = useParams();

  const processQuery = useQuery(
    ['process', processId],
    () => getProcess(processId)
      .then(({ data }) => data),
  );

  const rankingQuery = useQuery(
    ['ranking', processId],
    () => getExamRanking(processId)
      .then(({ data }) => data[0]),
  );

  const headers = useMemo(() => ([
    {
      id: 'name',
      label: 'Nombre',
      cell: item => item.exam.description,
    },
    {
      id: 'date',
      label: 'Fecha de evaluación',
      cell: item => formatDate(item.started_at),
    },
    {
      id: 'grade',
      label: 'Puntaje obtenido',
      cell: item => item.grade,
    },
    {
      id: 'good',
      label: '% de Acierto',
      cell: item => {
        const total = item.general_statistics.good + item.general_statistics.bad + item.general_statistics.not_answered;
        return `${Math.floor((item.general_statistics.good*100)/total)} %`;
      }
    },
    {
      id: 'detail',
      label: '',
      cell: item => (
        <Button
          type='border'
          size='small'
          weight={400}
          onClick={() => navigate(`/mis-examenes/${processId}/${item.uuid}`)}
          content='Ver resultados'
        />
      )
    }
  ]), []);

  const generalData = useMemo(() => ([
    ['Respuestas', 'Cantidad', { role: 'style' }],
    ['Correctas', processQuery.data?.general_statistics.good || 0, THEME.colors.positive.medium],
    ['Incorrectas', processQuery.data?.general_statistics.bad || 0, THEME.colors.warning.dark],
    ['No respondidas', processQuery.data?.general_statistics.not_answered || 0, THEME.colors.info.dark],
  ]), [processQuery.data]);

  const coursesData = useMemo(() => {
    let coursesArr = ['Cargando', 0, 0, 0];

    if (processQuery.data) {
      coursesArr = Object.keys(processQuery.data.course_statistics).map(course => ([
        course,
        processQuery.data.course_statistics[course].good,
        processQuery.data.course_statistics[course].bad,
        processQuery.data.course_statistics[course].not_answered,
      ]));
    }

    return ([
      ['Cursos', 'Correctas', 'Incorrectas', 'No respondidas'],
      ...coursesArr,
    ]);
  }, [processQuery.data]);

  return (
    <Container width='100%'>
      <Typography
        as='h2'
        fontFamily='Sans'
        color='primary'
        variant='darkest'
        fontSize={24}
        lineHeight={40}
        fontWeight={600}
        mb={32}
      >
        Resultados del examen
      </Typography>

      <Typography
        as='h3'
        color='primary'
        variant='dark'
        fontSize={20}
        lineHeight={24}
        fontWeight={700}
        mb={32}
      >
        Resultados generales
      </Typography>

      <Container width='100%' mb={32}>
        <Row gutter={32}>
          <Col md={5}>
            <Container width='100%' gap={8}>
              <Row gutter={8}>
                <Col offset={4} span={4}>
                  <StatsCard.General
                    icon={faAward}
                    quantity={processQuery.data?.grade}
                    description='Puntaje total'
                  />
                </Col>
                <Col span={4}>
                  <StatsCard.General
                    icon={faChartSimple}
                    quantity={rankingQuery.data?.rank}
                    description='Puesto'
                  />
                </Col>
              </Row>

              <Row gutter={8}>
                <Col offset={4} span={4}>
                  <StatsCard.General
                    icon={faCircleCheck}
                    quantity={processQuery.data?.general_statistics.good}
                    description='Respuestas correctas'
                  />
                </Col>
                <Col span={4}>
                  <StatsCard.General
                    icon={faCircleXmark}
                    quantity={processQuery.data?.general_statistics.bad}
                    description='Respuestas incorrectas'
                  />
                </Col>
              </Row>

              <Row gutter={8}>
                <Col offset={4} span={4}>
                  <StatsCard.General
                    icon={faCircleQuestion}
                    quantity={processQuery.data?.general_statistics.not_answered}
                    description='No respondidas'
                  />
                </Col>
                <Col span={4}>
                  <StatsCard.General
                    icon={faArrowTrendUp}
                    quantity={rankingQuery.data?.times}
                    description='Intentos'
                  />
                </Col>
              </Row>
            </Container>
          </Col>
          <Col md={7}>
            <Chart
              type='ColumnChart'
              height='400px'
              data={generalData}
              options={{
                title: 'Gráfica de resultados',
                titleTextStyle: {
                  color: THEME.colors.primary.dark,
                  fontSize: 12,
                  bold: true,
                },
              }}
            />
          </Col>
        </Row>
      </Container>

      <Typography
        as='h3'
        color='primary'
        variant='dark'
        fontSize={20}
        lineHeight={24}
        fontWeight={700}
        mb={32}
      >
        Resultados por examen
      </Typography>

      <Container width='100%' alignItems='center'>
        <DataTable
          card
          headers={headers}
          data={processQuery.data?.student_exams || []}
        />
      </Container>
    </Container>
  );
};

export default MyExamDetail;