import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { HEADER_HEIGHT, headerOptions, SIDE_MENU_WIDTH } from 'util/constants';
import { Container, Typography } from '../../../components/atoms';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faBars, faUser } from '@fortawesome/free-solid-svg-icons';
import { capitalize } from '../../../util/functions';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { NavLink } from 'react-router-dom';
import { media } from '../../../util/responsive';

const HeaderWrapper = styled.div`
  height: ${HEADER_HEIGHT}px;
  width: 100%;
  padding-left: 0;
  position: fixed;
  right: 0;
  box-shadow: 0 6px 12px 0 rgba(12, 39, 31, 0.20);
  // background-color:
  background-color: #F4F1F1;
  z-index: 99;
  
  ${media['md'](css`
    padding-left: ${SIDE_MENU_WIDTH}px;
  `)}
`;

const MenuContent = styled.div`
  cursor: pointer;
  position: relative;
  
  svg.fa-angle-down {
    color: ${props => props.theme.colors.primary.medium};
  }
`;

const HeaderMenu = styled.div`
  position: absolute;
  right: 0;
  top: calc(100% + 16px);
  padding: 8px;
  border-radius: 16px;
  box-shadow: 0 6px 12px 0 rgba(12, 39, 31, 0.20);
  background-color: ${props => props.theme.colors.primary.light};
  
  > div:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors.primary.dark};
  }
  
  ${media['md'](css`
    padding: 16px;
    left: 0;
  `)}
`;

const MenuOption = styled(NavLink)`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;
  padding: 16px 24px;
  color: ${props => props.theme.colors.textBlack};
`;

const Header = ({ onOpen }) => {
  const user = useSelector(state => state.session.user);
  const ref = useRef();
  const [showMenu, setShowMenu] = useState(false);

  useOutsideClick(ref, () => {
    setShowMenu(false);
  });

  return (
    <HeaderWrapper>
      <Container
        width='100%'
        height='100%'
        direction='row'
        justify='space-between'
        alignItems='center'
        padding={16}
        md={{
          justify: 'flex-end',
        }}
      >
        <Container
          display='block'
          md={{
            display: 'none'
          }}
          onClick={onOpen}
        >
          <FontAwesomeIcon icon={faBars} style={{ fontSize: 24 }} />
        </Container>
        <MenuContent
          ref={ref}
        >
          <Container
            direction='row'
            alignItems='center'
            gap={16}
            onClick={() => setShowMenu(state => !state)}
          >
            <FontAwesomeIcon icon={faUser} style={{ fontSize: 24 }} />
            <Container
              display='none'
              md={{
                display: 'flex',
              }}
            >
              <Typography
                as='span'
                fontSize={12}
                fontWeight={700}
                lineHeight={16}
              >
                {capitalize(user.first_name)} {capitalize(user.last_name)}
              </Typography>
              <Typography
                as='span'
                color='neutral'
                variant='medium'
                fontSize={10}
                lineHeight={16}
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  width: 150,
                }}
                title={capitalize(user.student_data.institution_career.career.name)}
              >
                {capitalize(user.student_data.institution_career.career.name)}
              </Typography>
            </Container>
            <FontAwesomeIcon icon={faAngleDown} style={{ fontSize: 24 }} />
          </Container>

          {showMenu && (
            <HeaderMenu>
              {headerOptions.map(option => (
                <MenuOption
                  key={option.label}
                  to={option.route}
                  onClick={() => setShowMenu(false)}
                >
                  <FontAwesomeIcon icon={option.icon} />
                  <Typography
                    fontSize={16}
                    lineHeight={24}
                  >
                    {option.label}
                  </Typography>

                </MenuOption>
              ))}
            </HeaderMenu>
          )}
        </MenuContent>


      </Container>
    </HeaderWrapper>
  );
};

export default Header;